import React from 'react'
import { Parallax } from 'react-parallax'
import { CFImage, CFView, DefaultScreen, MobileScreen } from 'components'
import { about, mobileAbout, aboutDivider } from 'images'

export default () => {
  return (
    <CFView>
      <MobileScreen>
        <Parallax bgImage={about} bgImageAlt="Entree Dish" strength={150}>
          <div
            style={{
              height: '400px',
              position: 'bottom',
            }}
          />
        </Parallax>
      </MobileScreen>
      <DefaultScreen>
        <CFView
          h="500px"
          image={`url(${about}) bottom / cover no-repeat`}
          style={{ backgroundAttachment: 'fixed' }}
        ></CFView>
      </DefaultScreen>
    </CFView>
  )
}
